import React, { useEffect, useState, useRef } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  APIHandoffRule,
  HandoffCommunicationType,
  APIContact,
} from '@super-software-inc/foundation'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { FlexRow, Label, PrimaryButton, TextInput } from 'components/lib'
import { omit } from 'lodash'
import { toastError, toastSuccess } from 'components/lib/Toast'
import FixedBottomButtons from 'components/lib/Forms/FixedBottomButtons'
import { MdCheckBox, MdCheckBoxOutlineBlank } from 'react-icons/md'
import { EditorRef } from 'components/app/Editor/types'

import {
  allHandoffRulesAtom,
  authenticatedUserAtom,
  handoffRuleFormAtom,
} from '../../../state/atoms'
import {
  createHandoffRule,
  updateHandoffRule,
} from '../../../api/assistant/handoffRule'
import { getContacts } from '../../../api/contacts/getContacts'

import PropertySelector from '../Tasks/PropertySelector'
import Editor from '../Editor/Editor'

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Title is required'),
  description: Yup.string().required('Prompt is required'),
  communicationType: Yup.array().required('Communication type is required'),
  associationIds: Yup.array()
    .of(Yup.string())
    .required('Association is required'),
})

const HandoffRuleForm = () => {
  const [handoffRuleForm, setHandoffRuleForm] =
    useRecoilState(handoffRuleFormAtom)
  const [, setAllHandoffRules] = useRecoilState(allHandoffRulesAtom)
  const { secrets, selectedContact } = useRecoilValue(authenticatedUserAtom)
  const [associationContacts, setAssociationContacts] = useState<APIContact[]>(
    [],
  )
  const editorRef = useRef<EditorRef>(null)
  const [tempDescription, setTempDescription] = useState('')

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.value = handoffRuleForm.handoffRule?.description || ''
    }
  }, [handoffRuleForm.handoffRule?.description])

  useEffect(() => {
    const fetchContacts = async () => {
      if (!selectedContact?.companyId) return

      try {
        const contacts = await getContacts(
          secrets.find(s => s.companyId === selectedContact.companyId),
          selectedContact.companyId,
          selectedContact.associationIds,
        )
        setAssociationContacts(contacts)
      } catch (error) {
        toastError('Error fetching contacts')
      }
    }

    fetchContacts()
  }, [selectedContact?.companyId, selectedContact.associationIds, secrets])

  const handleSubmit = async (values: Partial<APIHandoffRule>) => {
    const updatedHandoffRule = {
      ...(handoffRuleForm.handoffRule || {}),
      ...omit(values, 'id', 'createdAt', 'updatedAt'),
    }

    if (!selectedContact?.companyId) return

    try {
      if (handoffRuleForm.handoffRule.id) {
        // Update existing handoff rule
        const updatedRule = await updateHandoffRule(
          handoffRuleForm.handoffRule.id,
          selectedContact.companyId,
          updatedHandoffRule,
        )

        setAllHandoffRules(prevRules =>
          prevRules.map(rule =>
            rule.id === handoffRuleForm.handoffRule.id ? updatedRule : rule,
          ),
        )

        toastSuccess('Handoff rule updated')
      } else {
        // Create new handoff rule
        const newRule = await createHandoffRule(
          selectedContact.companyId,
          updatedHandoffRule,
        )
        // add error handling

        setAllHandoffRules(prevRules => [...prevRules, newRule])
        toastSuccess('Handoff rule created')
      }

      setHandoffRuleForm({
        handoffRule: {},
        isOpen: false,
      })
    } catch (error: any) {
      toastError(error?.message || 'Error saving handoff rule')
    }
  }

  const initialValues = {
    title: handoffRuleForm.handoffRule?.title || '',
    description: handoffRuleForm.handoffRule?.description || '',
    associationIds: handoffRuleForm.handoffRule?.associationIds || [],
    communicationType: handoffRuleForm.handoffRule?.communicationType || [
      HandoffCommunicationType.Voice,
    ],
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ errors, touched, setFieldValue, isSubmitting, values }) => (
        <Form className="bg-white rounded-lg">
          <div className="max-w-[500px] p-6 space-y-6">
            <h2 className="text-lg font-semibold text-gray-900">
              {handoffRuleForm.handoffRule.id
                ? 'Edit handoff rule'
                : 'Add/edit handoff'}
            </h2>

            <div className="space-y-1">
              <label
                htmlFor="title"
                className="block text-sm font-semibold text-gray-900"
              >
                Title
              </label>
              <Field
                id="title"
                name="title"
                as={TextInput}
                type="text"
                placeholder="Add a title"
              />
              {errors.title && touched.title && (
                <div className="text-sm text-red-600">{errors.title}</div>
              )}
            </div>

            <div className="space-y-1">
              <label
                htmlFor="associationIds"
                className="block text-sm font-semibold text-gray-900"
              >
                Accounts
              </label>

              <p className="text-[#627088] text-xs font-normal font-['Inter'] leading-[18px]">
                Leave blank if this handoff applies across your company. If it
                is limited to specific properties, select them below.
              </p>

              <PropertySelector
                width="100%"
                value={values.associationIds as string[]}
                onChange={(associationIds: string[]) => {
                  setFieldValue('associationIds', associationIds)
                }}
                allowMultiSelect
                isTaskSheet={false}
                includeCompany={false}
                placeholder="Company-wide"
              />

              {errors.associationIds && touched.associationIds && (
                <div className="text-sm text-red-600">
                  {errors.associationIds}
                </div>
              )}
            </div>
            <div className="space-y-1">
              <Label>Communication type</Label>
              <FlexRow align="center">
                {values.communicationType?.includes(
                  HandoffCommunicationType.Voice,
                ) ? (
                  <MdCheckBox
                    onClick={() => {
                      const newType = !values.communicationType
                        ? [HandoffCommunicationType.Voice]
                        : values.communicationType?.includes(
                            HandoffCommunicationType.Voice,
                          )
                        ? values.communicationType?.includes(
                            HandoffCommunicationType.Text,
                          )
                          ? [HandoffCommunicationType.Text]
                          : []
                        : [
                            ...values.communicationType,
                            HandoffCommunicationType.Voice,
                          ]

                      setFieldValue('communicationType', newType)
                    }}
                    style={{ fontSize: 18, marginRight: 5 }}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    onClick={() => {
                      const newType = !values.communicationType
                        ? [HandoffCommunicationType.Voice]
                        : values.communicationType?.includes(
                            HandoffCommunicationType.Voice,
                          )
                        ? values.communicationType?.includes(
                            HandoffCommunicationType.Text,
                          )
                          ? [HandoffCommunicationType.Text]
                          : []
                        : [
                            ...values.communicationType,
                            HandoffCommunicationType.Voice,
                          ]

                      setFieldValue('communicationType', newType)
                    }}
                    style={{
                      fontSize: 18,
                      color: '#C9CED6',
                      marginRight: 5,
                    }}
                  />
                )}
                <p>Call</p>
              </FlexRow>
              <FlexRow align="center">
                {values.communicationType?.includes(
                  HandoffCommunicationType.Text,
                ) ? (
                  <MdCheckBox
                    onClick={() => {
                      const newType = !values.communicationType
                        ? [HandoffCommunicationType.Text]
                        : values.communicationType?.includes(
                            HandoffCommunicationType.Text,
                          )
                        ? values.communicationType?.includes(
                            HandoffCommunicationType.Voice,
                          )
                          ? [HandoffCommunicationType.Voice]
                          : []
                        : [
                            ...values.communicationType,
                            HandoffCommunicationType.Text,
                          ]

                      setFieldValue('communicationType', newType)
                    }}
                    style={{ fontSize: 18, marginRight: 5 }}
                  />
                ) : (
                  <MdCheckBoxOutlineBlank
                    onClick={() => {
                      const newType = !values.communicationType
                        ? [HandoffCommunicationType.Text]
                        : values.communicationType?.includes(
                            HandoffCommunicationType.Text,
                          )
                        ? values.communicationType?.includes(
                            HandoffCommunicationType.Voice,
                          )
                          ? [HandoffCommunicationType.Voice]
                          : []
                        : [
                            ...values.communicationType,
                            HandoffCommunicationType.Text,
                          ]

                      setFieldValue('communicationType', newType)
                    }}
                    style={{
                      fontSize: 18,
                      color: '#C9CED6',
                      marginRight: 5,
                    }}
                  />
                )}
                <p>SMS or email</p>
              </FlexRow>
              {errors.communicationType && touched.communicationType && (
                <div className="text-sm text-red-600">
                  {errors.communicationType}
                </div>
              )}
            </div>

            <div className="space-y-1">
              <label
                htmlFor="description"
                className="block text-sm font-semibold text-gray-900"
              >
                Prompt
              </label>
              <p className="text-[#627088] text-xs font-normal font-['Inter'] leading-[18px] pb-2">
                Actions the agent can take on tasks include: assigning the task,
                adding subscribers, updating the workspace, adding categories,
                adding a due date, changing the task status, and sending an
                internal comment.
              </p>

              <p className="text-[#627088] text-xs font-normal font-['Inter'] leading-[18px] pb-2">
                During phone calls, your agent can take a message or transfer
                calls according to a phone tree. To trigger a phone tree,
                specify the phone numbers to dial; they will be dialed in the
                order they are written. If call cannot be connected to a
                recipient via phone tree, a message will be recorded.
              </p>

              <div
                style={{
                  border: '1px solid #E0E0E0',
                  borderRadius: '4px',
                  padding: '0 10px',
                  minHeight: '200px',
                }}
                onBlur={async () => {
                  if (tempDescription === values.description) {
                    return
                  }
                  setFieldValue('description', tempDescription)
                }}
              >
                <Editor
                  ref={editorRef}
                  placeholder="Specify your handoff rules"
                  associationId={undefined}
                  contacts={associationContacts}
                  onChange={val => {
                    setTempDescription(val)
                  }}
                  forceGroups
                />
              </div>
              {errors.description && touched.description && (
                <div className="text-sm text-red-600">{errors.description}</div>
              )}
            </div>
          </div>

          <FixedBottomButtons>
            <FlexRow style={{ gap: 10 }} justify="flex-end">
              <PrimaryButton
                type="button"
                light
                onClick={() => {
                  setHandoffRuleForm({
                    handoffRule: {},
                    isOpen: false,
                  })
                }}
              >
                Cancel
              </PrimaryButton>
              <PrimaryButton type="submit" disabled={isSubmitting}>
                {handoffRuleForm.handoffRule.id ? 'Update' : 'Next'}
              </PrimaryButton>
            </FlexRow>
          </FixedBottomButtons>
        </Form>
      )}
    </Formik>
  )
}

export default HandoffRuleForm
