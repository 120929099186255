import { Divider, FlexRow, PrimaryButton } from 'components/lib'
import React, { useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, routingStateAtom } from 'state/atoms'
import {
  AccessLevel,
  ResourceType,
  Feature,
  Action,
  CheckType,
  isAllowed,
} from '@super-software-inc/foundation'
import IntegrationsPage from './IntegrationsPage'
import RoutingRules from './Rules/RoutingRules'
import Templates from './Templates'
import AIAgent from './AIAgent'
import CategoriesSettings from './CategoriesSettings'

export enum CompanySettingsTabs {
  AI_AGENT = 'ai-agent',
  COMMUNICATIONS = 'communications',
  INTEGRATIONS = 'integrations',
  TEMPLATES = 'templates',
  CATEGORIES = 'categories',
}

const tabs = [
  {
    id: CompanySettingsTabs.AI_AGENT,
    name: 'AI Agent',
  },
  {
    id: CompanySettingsTabs.COMMUNICATIONS,
    name: 'Communications',
  },
  {
    id: CompanySettingsTabs.INTEGRATIONS,
    name: 'Integrations',
  },
  {
    id: CompanySettingsTabs.TEMPLATES,
    name: 'Templates',
  },
  {
    id: CompanySettingsTabs.CATEGORIES,
    name: 'Categories',
  },
]

const CompanySettings = () => {
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  // Filter tabs based on permissions
  const visibleTabs = useMemo(() => {
    const canManageCategories = isAllowed(
      authenticatedUser.selectedContact,
      authenticatedUser.acts,
      authenticatedUser.selectedContact.associationIds,
      ResourceType.PROPERTY,
      Feature.CATEGORIES,
      Action.EDIT,
      CheckType.ANY,
    )

    // TODO - this needs to be updated to ACT check
    const isAdmin = authenticatedUser.selectedContact.propertyInfo.some(
      propInfo => propInfo.accessLevel === AccessLevel.AdminAccess,
    )

    return tabs.filter(tab => {
      if (tab.id === CompanySettingsTabs.CATEGORIES) return canManageCategories
      return isAdmin
    })
  }, [authenticatedUser.selectedContact, authenticatedUser.acts])

  return (
    <>
      <FlexRow style={{ padding: 10, marginLeft: 25 }}>
        {visibleTabs.map(tab => (
          <PrimaryButton
            key={tab.id}
            light={routingState.companySettingsTab !== tab.id}
            onClick={() =>
              setRoutingState({
                ...routingState,
                companySettingsTab: tab.id,
              })
            }
            style={{ marginRight: 10 }}
          >
            {tab.name}
          </PrimaryButton>
        ))}
      </FlexRow>
      <Divider />
      {routingState.companySettingsTab === CompanySettingsTabs.AI_AGENT && (
        <AIAgent />
      )}
      {routingState.companySettingsTab ===
        CompanySettingsTabs.COMMUNICATIONS && <RoutingRules />}
      {routingState.companySettingsTab === CompanySettingsTabs.INTEGRATIONS && (
        <IntegrationsPage />
      )}
      {routingState.companySettingsTab === CompanySettingsTabs.TEMPLATES && (
        <Templates />
      )}
      {routingState.companySettingsTab === CompanySettingsTabs.CATEGORIES && (
        <CategoriesSettings />
      )}
    </>
  )
}

export default CompanySettings
