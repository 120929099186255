import React, { useEffect, useMemo } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import { FlexRow, PageTitle } from 'components/lib'
import { authenticatedUserAtom, routingStateAtom } from 'state/atoms'
import Tabs from 'components/lib/Navigation/Tabs'
import { PageHeader } from 'pages/Tasks'
import {
  isAllowed,
  ResourceType,
  Feature,
  Action,
  CheckType,
  AccessLevel,
} from '@super-software-inc/foundation'
import ProfileNotificationSettings from 'components/app/ProfileNotificationSettings'
import ProfileForm from 'components/app/ContactForm/ProfileForm'
import CompanySettings from './CompanySettings/CompanySettings'

/**
 * Encapsulates the entire ContentContainer layout, including the sidebar and the main content
 */
const Settings = () => {
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)

  const canManageCategories = useMemo(
    () =>
      isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        authenticatedUser.selectedContact.associationIds,
        ResourceType.PROPERTY,
        Feature.CATEGORIES,
        Action.EDIT,
        CheckType.ANY,
      ),
    [authenticatedUser],
  )

  const isAdmin = useMemo(
    () =>
      authenticatedUser.selectedContact.propertyInfo.some(
        propInfo => propInfo.accessLevel === AccessLevel.AdminAccess,
      ),
    [authenticatedUser],
  )

  useEffect(() => {
    if (routingState.settingsTab === 0 && !isAdmin && !canManageCategories) {
      setRoutingState({ ...routingState, settingsTab: 1 })
    }
  }, [canManageCategories, routingState, setRoutingState, isAdmin])

  const tabs = useMemo(
    () => [
      {
        name: isAdmin || canManageCategories ? 'Company settings' : '',
      },
      {
        name: 'My settings',
      },
      {
        name: 'My notifications',
      },

      {
        name: `Support`,
        trailingIcon: (
          <span
            className="material-symbols-rounded"
            style={{ fontSize: 14, paddingLeft: 5 }}
          >
            open_in_new
          </span>
        ),
      },
    ],
    [canManageCategories, isAdmin],
  )

  const onSelectTab = (tabIndex: number) => {
    if (tabIndex === 3) {
      window.open(
        'https://hiresuper.notion.site/Super-Support-bb16a9565f7c49958b2b6a32a2f7ceda',
        '_blank',
      )
    } else {
      setRoutingState({ ...routingState, settingsTab: tabIndex })
    }
  }

  return (
    <>
      <PageHeader>
        <FlexRow
          align="center"
          justify="space-between"
          style={{ width: '100%' }}
        >
          <PageTitle>Settings</PageTitle>
        </FlexRow>
      </PageHeader>

      <Tabs
        tabs={tabs}
        currentTab={routingState.settingsTab}
        setCurrentTab={onSelectTab}
        breakOnMobile
        breakOnTablet
        customStyle="top-14"
      />

      <>
        {routingState.settingsTab === 0 && <CompanySettings />}
        {routingState.settingsTab === 1 && (
          <div style={{ padding: 24 }}>
            <ProfileForm
              contact={authenticatedUser.selectedContact}
              onRequestClose={() => {}}
              isSettingsPage
            />
          </div>
        )}
        {routingState.settingsTab === 2 && <ProfileNotificationSettings />}
      </>
    </>
  )
}

export default Settings
