import React, { useEffect, useState } from 'react'
import {
  Divider,
  TableBody,
  Table,
  FlexRow,
  TextButton,
  PrimaryButton,
  TableCell,
  TableRow,
  TruncatedText,
  InputGroup,
  TextInput,
  TextAreaInput,
  Label,
  MultilevelDropdown,
  MultilevelItem,
  IconButton,
  Modal,
} from 'components/lib'
import * as Yup from 'yup'
import {
  TemplateType,
  Template,
  APITemplate,
  APIRule,
} from '@super-software-inc/foundation'
import { useTheme } from 'styled-components'
import { Positions } from 'components/lib/MultilevelDropdown'
import { Field, Formik } from 'formik'
import { toastSuccess } from 'components/lib/Toast'
import {
  addTemplate,
  getCompanyTemplates,
  updateTemplate,
  deleteTemplate,
} from 'api/templates'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  authenticatedUserAtom,
  editingRuleAtom,
  routingStateAtom,
} from 'state/atoms'
import { getRules } from 'api/rules'
import { useNavigate } from 'react-router'
import { CompanySettingsTabs } from './CompanySettings'

const companyVariables = [
  '{{company name}}',
  '{{company phone}}',
  '{{company email}}',
]

const taskVariables = [
  '{{title}}',
  '{{status}}',
  '{{description}}',
  '{{categories}}',
  '{{property name}}',
  '{{location}}',
  '{{due date}}',
  '{{urgency}}',
  '{{assignee}}',
  '{{subscribers}}',
]

const workOrderVariables = ['{{vendor}}', '{{budget}}', '{{time log}}']

const complianceVariables = [
  '{{vendor}}',
  '{{budget}}',
  '{{time log}}',
  '{{inspection type}}',
]

const TemplateSchema = Yup.object({
  title: Yup.string().required('Required'),
  description: Yup.string().required('Required'),
})

const VariableSection = ({
  title,
  variables,
  setFieldValue,
  existingDescription,
}: {
  title: string
  variables: string[]
  setFieldValue: Function
  existingDescription: string
}) => (
  <FlexRow
    align="center"
    style={{ marginBottom: 12, marginTop: 12, gap: 2, flexWrap: 'wrap' }}
  >
    <p style={{ color: '#627088', fontWeight: 700 }}>{title}:</p>

    {variables.map((variable: string) => (
      <TextButton
        key={variable}
        type="button"
        onClick={() =>
          // append to description field
          setFieldValue('description', `${existingDescription} ${variable}`)
        }
      >
        {variable}
      </TextButton>
    ))}
  </FlexRow>
)

const Templates = () => {
  const [templateForm, setTemplateForm] = useState({
    isOpen: false,
    templateId: '',
  })
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  const [templates, setTemplates] = useState<any>([])
  const [rulesUsingTemplate, setRulesUsingTemplate] = useState<APIRule[]>([])
  const [, setEditingRule] = useRecoilState(editingRuleAtom)
  const [routingState, setRoutingState] = useRecoilState(routingStateAtom)
  const navigate = useNavigate()

  const theme = useTheme()

  // get all the templates from company
  useEffect(() => {
    const getTemplates = async () => {
      const companyTemplates = await getCompanyTemplates(selectedCompany.id, [
        TemplateType.COMMENT,
      ])
      setTemplates(companyTemplates)
    }
    getTemplates()
  }, [selectedCompany.id])

  const deleteCompanyTemplate = async (templateId: string) => {
    // check to see if any rules use the template before deleting
    const rules = await getRules(selectedCompany.id)

    const ruleUsingTemplate = rules.filter(rule =>
      rule.actions.onMatch.find(
        action =>
          'target' in action &&
          action.target.key === 'savedReplyId' &&
          'value' in action &&
          action.value === templateId,
      ),
    )

    // if the template is being used in a rule, show a modal with the rules
    if (ruleUsingTemplate.length > 0) {
      setRulesUsingTemplate(ruleUsingTemplate)
      return
    }

    // delete template from api
    await deleteTemplate(selectedCompany.id, templateId)

    setTemplates(
      templates.filter((template: APITemplate) => template.id !== templateId),
    )
    toastSuccess('Template deleted')
  }

  if (templateForm.isOpen) {
    return (
      <div style={{ padding: 20, marginLeft: 20 }}>
        <Formik
          validationSchema={TemplateSchema}
          initialValues={
            templateForm.templateId.length > 0
              ? ({
                  title:
                    templates.find(
                      (template: any) =>
                        template.id === templateForm.templateId,
                    )?.title || '',
                  description:
                    templates.find(
                      (template: any) =>
                        template.id === templateForm.templateId,
                    )?.description || '',
                  type: 'comment',
                } as Template)
              : ({ title: '', description: '', type: 'comment' } as Template)
          }
          onSubmit={async values => {
            if (templateForm.templateId.length > 0) {
              const updatedTemplate = await updateTemplate(selectedCompany.id, {
                id: templateForm.templateId,
                ...values,
              })
              if (updatedTemplate) {
                setTemplates(
                  templates.map((template: any) =>
                    template.id === templateForm.templateId
                      ? updatedTemplate
                      : template,
                  ),
                )
                setTemplateForm({
                  isOpen: false,
                  templateId: '',
                })
              }
            } else {
              const newTemplate = await addTemplate(
                selectedCompany.id,
                values as Template,
              )
              if (newTemplate) {
                setTemplates([...templates, newTemplate])
                setTemplateForm({ isOpen: false, templateId: '' })
              }
            }
          }}
        >
          {({ values, isValid, handleChange, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <h2>Add comment template</h2>
              <VariableSection
                title="Company variables"
                variables={companyVariables}
                setFieldValue={setFieldValue}
                existingDescription={values.description}
              />
              <VariableSection
                title="Task variables"
                variables={taskVariables}
                setFieldValue={setFieldValue}
                existingDescription={values.description}
              />
              <VariableSection
                title={`"Work orders" variables`}
                variables={workOrderVariables}
                setFieldValue={setFieldValue}
                existingDescription={values.description}
              />
              <VariableSection
                title={`"Compliance" variables`}
                variables={complianceVariables}
                setFieldValue={setFieldValue}
                existingDescription={values.description}
              />
              <InputGroup>
                <Label>Title</Label>
                <Field
                  type="text"
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  placeholder="Give this template a title"
                  as={TextInput}
                />
              </InputGroup>
              <InputGroup>
                <Label>Description</Label>
                <Field
                  type="text"
                  name="description"
                  value={values.description}
                  onChange={handleChange}
                  placeholder="Compose comment"
                  as={TextAreaInput}
                />
              </InputGroup>
              <FlexRow
                justify="flex-end"
                align="center"
                style={{ marginTop: 10 }}
              >
                <PrimaryButton
                  type="button"
                  onClick={() =>
                    setTemplateForm({ isOpen: false, templateId: '' })
                  }
                  style={{ marginRight: 10 }}
                  light
                >
                  Cancel
                </PrimaryButton>
                <PrimaryButton
                  // disabled={!isValid}
                  type="submit"
                >
                  Save
                </PrimaryButton>
              </FlexRow>
            </form>
          )}
        </Formik>
      </div>
    )
  }
  return (
    <div style={{ padding: 20, marginLeft: 20 }}>
      <FlexRow justify="space-between">
        <h2>Comment templates</h2>
        <TextButton
          onClick={() => setTemplateForm({ isOpen: true, templateId: '' })}
        >
          <span className="material-symbols-rounded" style={{ fontSize: 20 }}>
            add
          </span>
          <p style={{ fontSize: 14 }}>Add</p>
        </TextButton>
      </FlexRow>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <p
        style={{
          color: '#627088',
          fontWeight: 600,
          marginTop: 10,
          fontSize: 12,
        }}
      >
        TITLE
      </p>
      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <Table>
        <TableBody>
          {templates.map((item: any) => (
            <TableRow key={`${item.title} - ${item.description}`}>
              <TableCell style={{ width: 35 }}>
                <FlexRow
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#F1F2F4',
                    borderColor: '#C9CED6',
                    borderWidth: 0.5,
                    height: 24,
                    width: 24,
                    padding: 5,
                    borderRadius: 100,
                  }}
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 16 }}
                  >
                    list_alt
                  </span>
                </FlexRow>
              </TableCell>
              <TableCell>
                <FlexRow>
                  <TruncatedText style={{ color: '#0A1F44' }}>
                    <strong style={{ fontWeight: 500 }}>{item.title}</strong>
                    <span style={{ color: '#627088', marginLeft: 10 }}>
                      {item.description}
                    </span>
                  </TruncatedText>
                </FlexRow>
              </TableCell>
              <TableCell style={{ width: 40 }}>
                <MultilevelDropdown
                  position={Positions.Left}
                  trigger={<IconButton />}
                  title={
                    <span
                      className="material-symbols-rounded"
                      style={{ fontSize: 20 }}
                    >
                      more_horiz
                    </span>
                  }
                >
                  <MultilevelItem
                    onClick={() =>
                      setTemplateForm({ isOpen: true, templateId: item.id })
                    }
                  >
                    Edit template
                  </MultilevelItem>

                  <MultilevelItem
                    onClick={() => deleteCompanyTemplate(item.id)}
                  >
                    <span
                      style={{ color: theme.colors.red, cursor: 'pointer' }}
                    >
                      Delete template
                    </span>
                  </MultilevelItem>
                </MultilevelDropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Modal
        isOpen={rulesUsingTemplate.length > 0}
        onRequestClose={() => setRulesUsingTemplate([])}
        contentLabel="Cannot delete template"
        style={{
          content: {
            maxWidth: 450,
          },
        }}
      >
        <div className="w-[400px] text-sky-950 text-xl font-bold font-['Inter'] leading-loose">
          Cannot delete template
        </div>
        <div className="w-[400px]">
          <span className="mb-10 text-slate-500 text-sm font-normal font-['Inter'] leading-tight">
            This template is used in one or more rules. Before you can delete
            this template, it has to be disassociated from all rules that use
            it:
            <br />
          </span>
          <ul className="m-5 text-slate-500">
            {rulesUsingTemplate.map((rule: APIRule) => (
              <li className="list-disc">
                <span
                  onClick={() => {
                    setRoutingState({
                      ...routingState,
                      companySettingsTab: CompanySettingsTabs.TEMPLATES,
                      settingsTab: 0,
                    })
                    setEditingRule({
                      id: rule.id,
                    } as APIRule)

                    navigate('/settings')
                  }}
                  className="cursor-pointer text-slate-500 text-sm font-normal font-['Inter'] underline leading-tight"
                >
                  {rule.title}
                </span>
              </li>
            ))}
          </ul>
          <FlexRow justify="flex-end" style={{ marginTop: 20 }}>
            <PrimaryButton onClick={() => setRulesUsingTemplate([])}>
              Okay
            </PrimaryButton>
          </FlexRow>
        </div>
      </Modal>
    </div>
  )
}

export default Templates
