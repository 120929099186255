import React from 'react'
import TagColors from 'types/TagColors'
import { Dot, FlexRow, ListItem } from 'components/lib'

const ColorSelector = ({
  selectedColor,
  onClick,
}: {
  selectedColor: string
  onClick: (color: string) => void
}) => (
  <div style={{ padding: '0' }}>
    {TagColors.map(color => (
      <ListItem
        key={color.primaryColor}
        active={color.primaryColor === selectedColor}
        onClick={() => {
          onClick(color.primaryColor)
        }}
        style={{
          padding: '0px 12px 0px 12px',
          border: 'none',
          cursor: 'pointer',
          whiteSpace: 'nowrap',
        }}
      >
        <FlexRow align="center" justify="flex-start">
          <div
            style={{
              width: 20,
              height: 20,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Dot color={color.primaryColor} size={10} />
          </div>
          <p style={{ marginLeft: 8, marginRight: 12 }}>{color.label}</p>
        </FlexRow>
      </ListItem>
    ))}
  </div>
)

export default ColorSelector
