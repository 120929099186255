import {
  addDoc,
  collection,
  serverTimestamp,
  doc,
  getDoc,
} from 'firebase/firestore'
import { firestore } from '../../firebase/setup'

// eslint-disable-next-line import/prefer-default-export
export const addNewCategory = async (
  companyId: string,
  categoryType: string, // ex. taskCategories, filesCategories
  name: string,
  color: string = 'Gray',
) => {
  const categoryCollection = collection(
    firestore,
    'companies',
    companyId,
    categoryType,
  )

  const newCategory = await addDoc(categoryCollection, {
    name,
    color,
    createdAt: serverTimestamp(),
  })

  const docRef = doc(categoryCollection, newCategory.id)
  const docSnap = await getDoc(docRef)
  return { ...docSnap.data(), id: newCategory.id }
}
