import React, { useEffect, useMemo, useState } from 'react'
import {
  Divider,
  FlexColumn,
  FlexRow,
  IconButton,
  LoadingIndicator,
  MultilevelDropdown,
  MultilevelItem,
  Table,
  TableBody,
  TableCell,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { Positions } from 'components/lib/MultilevelDropdown'
import {
  ActionType,
  APIRule,
  ConditionValue,
  Rule,
} from '@super-software-inc/foundation'
import {
  getRules,
  createRule,
  updateRule,
  updateRulesOrder,
  deleteRule,
  getRule,
} from 'api/rules'
import DroppableContainer from 'components/lib/Draggable/DroppableContainer'
import SortableItem from 'components/lib/Draggable/SortableItem'
import { getFunctions } from 'firebase/functions'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, editingRuleAtom } from 'state/atoms'
import { BackHandOutlined } from '@mui/icons-material'
import { useTheme } from 'styled-components'
import { HiSparkles } from 'react-icons/hi2'
import { toastError, toastSuccess } from 'components/lib/Toast'
import RuleForm from './RuleForm'
import {
  routeByTopicTemplate,
  customTemplate,
  routeByCategoryTemplate,
  routeByWorkspaceTemplate,
} from './ruleTemplates'

const RoutingRules = () => {
  const [ruleFormOpen, setRuleFormOpen] = useState(false)
  const [editingRule, setEditingRule] = useRecoilState(editingRuleAtom)
  const theme = useTheme()
  const functions = getFunctions()
  functions.region = 'us-east1'
  const [lockedRules, setLockedRules] = useState<APIRule[]>([])
  const [customRules, setCustomRules] = useState<APIRule[]>([])
  const { selectedCompany } = useRecoilValue(authenticatedUserAtom)
  const [loading, setLoading] = useState(false)
  const [formIsSubmitting, setFormIsSubmitting] = useState(false)

  useEffect(() => {
    // get rules
    const fetchRules = async () => {
      setLoading(true)
      const rules = await getRules(selectedCompany.id)
      // don't show library match rules to the user, which are assigning a property
      setLockedRules(
        rules
          .filter(
            rule =>
              rule.locked &&
              rule.conditions[0]?.matches.type !== 'library-match',
          )
          // group 2 first, then by order
          .sort((a, b) =>
            a.conditions.length === 0 && b.conditions.length !== 0
              ? -1
              : a.conditions.length !== 0 && b.conditions.length === 0
              ? 1
              : a.order - b.order,
          ),
      )
      setCustomRules(
        rules.filter(rule => !rule.locked).sort((a, b) => a.order - b.order),
      )

      if (editingRule.id && editingRule.id?.length > 0) {
        try {
          const pendingRule = await getRule(selectedCompany.id, editingRule.id)
          if (pendingRule) {
            setEditingRule(pendingRule)
            setRuleFormOpen(true)
            setLoading(false)
            return
          }
        } catch (error) {
          toastError(`${error}`)
        }
      }
      setEditingRule({} as APIRule)
      setRuleFormOpen(false)

      setLoading(false)
    }
    fetchRules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompany.id])

  const deleteExistingRule = async (ruleId: string) => {
    try {
      // delete rule
      await deleteRule(selectedCompany.id, ruleId)
      // remove rule from state
      setCustomRules(customRules.filter(rule => rule.id !== ruleId))
      toastSuccess('Rule successfully deleted.')
    } catch (error) {
      toastError('Error deleting rule')
    }
  }

  const updateOrCreateRule = async () => {
    setFormIsSubmitting(true)
    const newRule = { ...editingRule } as APIRule
    // validate all the form fields are filled out

    if (!newRule.title) {
      toastError('Please enter a title')
      setFormIsSubmitting(false)
      return
    }
    // remove conditions that have a value length of more than 0
    newRule.conditions = editingRule.conditions.filter(condition => {
      if ('str' in condition.matches) {
        return condition.matches.str.length > 0
      }
      return true
    })

    if (newRule.conditions.length === 0 && !newRule.locked) {
      toastError('Please add a condition')
      setFormIsSubmitting(false)
      return
    }

    // remove any static actions that have no value

    newRule.actions = {
      ...editingRule.actions,
      onMatch: editingRule.actions.onMatch.filter(action => {
        if ('value' in action) {
          if (action.target.key === 'assignee') {
            // locked rules are allowed to have no assignee, but keep the action on the rule.
            if (newRule.locked) {
              return true
            }
            return 'contactId' in action.value
          }
          return action.value.length > 0 || action.value === true
        }
        return true
      }),
    }

    // Add an onFail array of nothing (for now):
    newRule.actions.onFail = []

    if (newRule.actions.onMatch.length === 0) {
      setFormIsSubmitting(false)
      toastError('Please add an action')
      return
    }

    // save the form
    if (newRule.id.length > 0) {
      // update rule

      const rule = await updateRule(selectedCompany.id, newRule)

      setFormIsSubmitting(false)

      if (rule) {
        // update rule in state
        if (rule.locked) {
          setLockedRules(lockedRules.map(r => (r.id === rule.id ? rule : r)))
        } else {
          setCustomRules(customRules.map(r => (r.id === rule.id ? rule : r)))
        }
        toastSuccess(`Rule successfully updated`)
        setRuleFormOpen(false)
      }
    } else {
      // create rule

      newRule.order = customRules.length + 1
      const rule = await createRule(selectedCompany.id, newRule as Rule)

      setFormIsSubmitting(false)

      if (rule) {
        toastSuccess(`Rule successfully created`)

        if (rule.locked) {
          setLockedRules([...lockedRules, rule])
        } else {
          setCustomRules([...customRules, rule])
        }

        setRuleFormOpen(false)
        setEditingRule({} as APIRule)

        setRuleFormOpen(false)
      }
    }
  }

  const updateNewOrder = async () => {
    await updateRulesOrder(selectedCompany.id, customRules)
  }

  const reorderRules = async (oldIndex: number, newIndex: number) => {
    const newRules = [...customRules]
    const [removed] = newRules.splice(oldIndex, 1)
    newRules.splice(newIndex, 0, removed)
    setCustomRules(newRules)
    await updateRulesOrder(selectedCompany.id, newRules)
  }

  useEffect(() => {
    const orders = customRules.map(rule => rule.order)
    // if the orders are not in numberical order, update them
    if (!orders.every((value, index) => value === index + 1)) {
      updateNewOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customRules])

  // on unmount reset the editing rule, so the form doesn't appear when naving back to the page.
  useEffect(
    () => () => {
      setEditingRule({} as APIRule)
      setRuleFormOpen(false)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const responses = useMemo(
    () => [
      {
        icon: 'call',
        name: 'Reply to new phone call',
        description: selectedCompany.mainPhoneLine
          ? `Thank you for contacting {{ company name }}. If this is an emergency, please hang up and dial 911. How can we help you? To log a message with management, press 1. To reach the main line, press 2.`
          : `Thank you for contacting {{ company name }}. If this is an emergency, please hang up and dial 911. How can we help you? You can leave a message with management for us to log. When you're done, hang up at any time.`,
      },
      {
        icon: 'location_on',
        name: 'Tag inbound communications with property',
        description: 'If property is recognized, tag it with the property.',
      },
      {
        icon: 'pin_drop',
        name: 'Tag inbound communications with unit',
        description: 'If unit is recognized, tag it with the unit.',
      },
      {
        icon: 'notifications_active',
        name: 'Remind about upcoming lease expirations',
        description:
          'If a lease is ending, create a task 90 days before. Assign the default property manager and subscribe the owner.',
      },
      {
        icon: 'notifications_active',
        name: 'Remind about resident move outs',
        description:
          'If a resident term is ending, create a task 90 days before. Assign the default property manager and subscribe the owner.',
      },
      {
        icon: 'notifications_active',
        name: 'Remind about owner move outs',
        description:
          'If an owner term is ending, create a task 7 days before. Assign the default property manager and subscribe the owner.',
      },
    ],
    [selectedCompany],
  )

  const generateRuleDescription = (rule: Rule) => {
    const description = rule.actions.onMatch
      .map(action => action.title)
      .filter(title => title.length > 0)
      .join(', ')

    if (description.length === 0) {
      return (
        <span style={{ color: theme.colors.error }}>No action selected</span>
      )
    }
    return description
  }

  if (loading) {
    return <LoadingIndicator />
  }

  if (ruleFormOpen) {
    return (
      <RuleForm
        closeForm={() => {
          setRuleFormOpen(false)
          setFormIsSubmitting(false)
        }}
        rule={editingRule}
        setRule={setEditingRule}
        updateOrCreateRule={updateOrCreateRule}
        formIsSubmitting={formIsSubmitting}
      />
    )
  }
  return (
    <div
      style={{ padding: 20, paddingTop: 0, marginLeft: 20, marginBottom: 50 }}
    >
      <h2 style={{ marginTop: 10 }}>Smart Rules</h2>
      <p style={{ color: theme.colors.text250 }}>
        Super automates standard operating procedures for your business. We
        provide default rules, and you can train your AI assistant on custom
        rules.
      </p>
      <h4 style={{ marginTop: 20 }}>Default rules</h4>
      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <p
        style={{
          color: '#627088',
          fontWeight: 600,
          marginTop: 10,
          fontSize: 12,
        }}
      >
        BY PROPERTY
      </p>
      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <Table>
        <TableBody>
          {responses.map(response => (
            <TableRow key={response.name}>
              <TableCell style={{ width: 35 }}>
                <FlexRow
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: theme.colors.bg250,
                    borderColor: '#C9CED6',
                    borderWidth: 0.5,
                    height: 24,
                    width: 24,
                    padding: 5,
                    borderRadius: 100,
                  }}
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 16 }}
                  >
                    {response.icon}
                  </span>
                </FlexRow>
              </TableCell>
              <TableCell>
                <FlexRow>
                  <TruncatedText style={{ color: '#627088' }}>
                    <strong
                      style={{
                        color: '#0A1F44',
                        fontWeight: 500,
                        paddingRight: 10,
                      }}
                    >
                      {response.name}
                    </strong>
                    {response.description === 'No action selected' ? (
                      <span style={{ color: 'red' }}>
                        {response.description}
                      </span>
                    ) : (
                      response.description
                    )}
                  </TruncatedText>
                </FlexRow>
              </TableCell>
              <TableCell style={{ width: 35 }} />
            </TableRow>
          ))}
          {lockedRules.map(rule => (
            <TableRow key={rule.title}>
              <TableCell style={{ width: 35 }}>
                <FlexRow
                  align="center"
                  justify="center"
                  style={{
                    backgroundColor: '#F1F2F4',
                    borderColor: '#C9CED6',
                    borderWidth: 0.5,
                    height: 24,
                    width: 24,
                    padding: 5,
                    borderRadius: 100,
                  }}
                >
                  <span
                    className="material-symbols-rounded"
                    style={{ fontSize: 16 }}
                  >
                    <span
                      className="material-symbols-rounded"
                      style={{
                        fontSize: 16,
                        color: '#6938EF',
                      }}
                    >
                      {rule.locked && rule.conditions.length === 0
                        ? 'mail'
                        : rule.conditions[0]?.assert.value ===
                          ConditionValue.COMPANY_ID
                        ? 'business'
                        : 'location_on'}
                    </span>
                  </span>
                </FlexRow>
              </TableCell>
              <TableCell>
                <FlexRow>
                  <TruncatedText style={{ color: '#627088' }}>
                    <strong
                      style={{
                        color: '#0A1F44',
                        fontWeight: 500,
                        paddingRight: 10,
                      }}
                    >
                      {rule.title}
                    </strong>
                    {generateRuleDescription(rule)}
                  </TruncatedText>
                </FlexRow>
              </TableCell>
              <TableCell style={{ width: 35 }}>
                <MultilevelDropdown
                  trigger={<IconButton />}
                  title={
                    <span
                      className="material-symbols-rounded"
                      style={{ fontSize: 16 }}
                    >
                      more_horiz
                    </span>
                  }
                >
                  <MultilevelItem
                    onClick={() => {
                      setRuleFormOpen(true)
                      setEditingRule(rule)
                    }}
                  >
                    <p>Edit rule</p>
                  </MultilevelItem>
                </MultilevelDropdown>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <FlexRow align="center" justify="space-between" style={{ marginTop: 20 }}>
        <h4>Custom rules</h4>
        <MultilevelDropdown
          position={Positions.TopLeft}
          trigger={<IconButton />}
          title={
            <FlexRow align="center" justify="center">
              <p style={{ fontSize: 14 }}>+ Add</p>
            </FlexRow>
          }
        >
          <p
            style={{
              color: '#627088',
              padding: 12,
              paddingLeft: 15,
            }}
          >
            Templates:
          </p>
          <MultilevelItem
            onClick={() => {
              setRuleFormOpen(true)
              setEditingRule(routeByTopicTemplate)
            }}
          >
            <p>Route by topic</p>
          </MultilevelItem>
          <MultilevelItem
            onClick={() => {
              setRuleFormOpen(true)
              setEditingRule(routeByCategoryTemplate)
            }}
          >
            <p>Organize by category</p>
          </MultilevelItem>
          <MultilevelItem
            onClick={() => {
              setRuleFormOpen(true)
              setEditingRule(routeByWorkspaceTemplate)
            }}
          >
            <p>Organize by workspace</p>
          </MultilevelItem>
          <Divider style={{ marginBottom: 10, marginTop: 10, width: '90%' }} />
          <MultilevelItem
            onClick={() => {
              setRuleFormOpen(true)
              setEditingRule(customTemplate)
            }}
          >
            <p>Blank template</p>
          </MultilevelItem>
        </MultilevelDropdown>
      </FlexRow>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <FlexRow
        justify="space-between"
        align="center"
        style={{ marginBottom: 10, marginTop: 10 }}
      >
        <p
          style={{
            color: '#627088',
            fontWeight: 600,
            fontSize: 12,
          }}
        >
          TITLE
        </p>
        <FlexRow align="center" justify="center" style={{ marginRight: 35 }}>
          <p
            style={{
              color: '#627088',
              fontWeight: 600,
              fontSize: 12,
            }}
          >
            RUN ORDER
          </p>
          <Tooltip
            overlay={
              <span>
                This defines the sequencing of how your rules are applied. We
                will run the rules in this order, starting from the number 1.
              </span>
            }
            placement="top"
          >
            <span
              className="material-symbols-rounded"
              style={{
                fontSize: 16,
                cursor: 'help',
                paddingLeft: 5,
                color: '#627088',
              }}
            >
              help
            </span>
          </Tooltip>
        </FlexRow>
      </FlexRow>

      <Table>
        <TableBody>
          <DroppableContainer
            items={customRules}
            sortableItems={customRules.map((rule, index) => (
              <SortableItem
                key={rule.id}
                id={rule.id}
                isTableRow
                style={{
                  borderTop: '1px solid #E5E7EB',
                  borderBottom: '1px solid #E5E7EB',
                  backgroundColor: '#fff',
                }}
              >
                <TableCell style={{ width: 25 }}>
                  <FlexRow>
                    <span
                      className="material-symbols-rounded"
                      style={{ fontSize: 16, cursor: 'grab', color: '#627088' }}
                    >
                      drag_indicator
                    </span>
                  </FlexRow>
                </TableCell>
                <TableCell
                  className="border-b border-zinc-200"
                  style={{ width: 35 }}
                >
                  <FlexRow
                    align="center"
                    justify="center"
                    style={{
                      backgroundColor: '#F1F2F4',
                      borderColor: '#C9CED6',
                      borderWidth: 0.5,
                      height: 24,
                      width: 24,
                      padding: 5,
                      borderRadius: 100,
                    }}
                  >
                    <HiSparkles className="text-violet-600 text-[18px] font-semibold" />
                  </FlexRow>
                </TableCell>
                <TableCell style={{ width: 'inherit' }}>
                  <FlexRow>
                    <TruncatedText style={{ color: '#627088' }}>
                      <strong
                        style={{
                          color: '#0A1F44',
                          fontWeight: 500,
                          paddingRight: 10,
                        }}
                      >
                        {rule.title}
                      </strong>
                      {rule.actions.onMatch.find(
                        action => action.type === ActionType.HALT,
                      ) && (
                        <Tooltip
                          overlay={
                            <span>
                              Do not run rules that come next in sequence
                            </span>
                          }
                          placement="top"
                        >
                          <BackHandOutlined
                            style={{
                              fontSize: 18,
                              color: theme.colors.secondaryPurple600,
                              marginRight: '.5rem',
                            }}
                          />
                        </Tooltip>
                      )}
                      <Tooltip
                        overlay={
                          <span>
                            {rule.actions.onMatch
                              .map(action => action.title)
                              .filter(title => title.length > 0)
                              .join(', ')}
                          </span>
                        }
                        placement="top"
                      >
                        <span>
                          {rule.actions.onMatch
                            .filter(action => action.title.length > 1)
                            .map(action => action.title)
                            .join(', ')}
                        </span>
                      </Tooltip>
                    </TruncatedText>
                  </FlexRow>
                </TableCell>

                <TableCell
                  style={{
                    width: 100,
                    borderRight: '1px solid #E5E7EB',
                    borderLeft: '1px solid #E5E7EB',
                  }}
                >
                  <FlexRow
                    align="center"
                    justify="space-between"
                    style={{
                      color: '#627088',
                    }}
                  >
                    <TruncatedText>
                      {/* Showing the index as the order, as to prevent a lag in the number updating */}
                      <strong style={{ paddingRight: 10, paddingLeft: 10 }}>
                        {index + 1}
                      </strong>
                    </TruncatedText>
                    <FlexColumn style={{ marginRight: 10 }}>
                      <span
                        className="material-symbols-rounded"
                        style={{
                          cursor: 'pointer',
                          fontSize: 16,
                          height: 15,
                        }}
                        onClick={() => {
                          reorderRules(index, index - 1)
                        }}
                      >
                        keyboard_arrow_up
                      </span>
                      <span
                        className="material-symbols-rounded"
                        style={{
                          cursor: 'pointer',
                          fontSize: 16,
                          height: 15,
                        }}
                        onClick={() => {
                          reorderRules(index, index + 1)
                        }}
                      >
                        keyboard_arrow_down
                      </span>
                    </FlexColumn>
                  </FlexRow>
                </TableCell>
                <TableCell style={{ width: 35 }}>
                  <MultilevelDropdown
                    trigger={<IconButton />}
                    title={
                      <span
                        className="material-symbols-rounded"
                        style={{ fontSize: 16 }}
                      >
                        more_horiz
                      </span>
                    }
                  >
                    <MultilevelItem
                      onClick={() => {
                        setRuleFormOpen(true)
                        setEditingRule(rule)
                      }}
                    >
                      <p>Edit rule</p>
                    </MultilevelItem>
                    <MultilevelItem
                      onClick={() => {
                        deleteExistingRule(rule.id)
                      }}
                    >
                      <p
                        style={{
                          color: theme.colors.error,
                        }}
                      >
                        Delete rule
                      </p>
                    </MultilevelItem>
                  </MultilevelDropdown>
                </TableCell>
              </SortableItem>
            ))}
            setItems={async (items: APIRule[]) => {
              setCustomRules(items)
            }}
          />
          {customRules.length === 0 && (
            <TableRow>
              <TableHeaderCell
                style={{
                  textTransform: 'none',
                  textAlign: 'left',
                  fontWeight: 500,
                  borderTop: '1px solid #E5E7EB',
                }}
              >
                {' '}
                No custom rules to display.{' '}
                <span
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => {
                    setRuleFormOpen(true)
                    setEditingRule(customTemplate)
                  }}
                >
                  Create your first custom rule
                </span>{' '}
                to get started.
              </TableHeaderCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}

export default RoutingRules
