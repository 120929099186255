import { Category } from '@super-software-inc/foundation'
import { addNewCategory } from 'api/categories'
import {
  Divider,
  FlexColumn,
  FlexRow,
  IconButton,
  PrimaryButton,
  TextButton,
  MultilevelDropdown,
  MultilevelItem,
  LoadingIndicator,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TruncatedText,
  Modal,
  TextInput,
} from 'components/lib'
import { deleteDoc, doc, updateDoc } from 'firebase/firestore'
import { orderBy } from 'lodash'
import { companyTaskCategoriesAtom } from 'pages/Tasks'
import React, { useState } from 'react'
import { useFirestore } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { useTheme } from 'styled-components'
import { authenticatedUserAtom } from 'state/atoms'
import ColorSelector from 'components/app/Tags/ColorSelector'
import { Positions } from 'components/lib/MultilevelDropdown'
import { Menu } from '@mui/material'
import TagColors from 'types/TagColors'

interface ErrorMessage {
  title: string
  message: string
}

const CategoriesSettings: React.FC = () => {
  const [companyTaskCategories, setCompanyTaskCategories] = useRecoilState(
    companyTaskCategoriesAtom,
  )
  const [editingCategory, setEditingCategory] = useState<Category>(
    {} as Category,
  )
  const [addCategoryOpen, setAddCategoryOpen] = useState(false)
  const [newCategory, setNewCategory] = useState<{
    label: string
    color?: string
  }>({
    label: '',
    color: '',
  })
  const theme = useTheme()

  const [errorMessage, setErrorMessage] = useState<ErrorMessage>({
    title: '',
    message: '',
  })

  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  const { selectedCompany } = authenticatedUser
  const firestore = useFirestore()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [addAnchorEl, setAddAnchorEl] = useState<null | HTMLElement>(null)

  const updateCategory = async () => {
    if (!editingCategory || editingCategory.name.length < 1) {
      setErrorMessage({
        title: 'Please enter a value',
        message: 'You can’t save an empty category name.',
      })
      setEditingCategory({} as Category)
      return
    }

    const catRef = doc(
      firestore,
      'companies',
      selectedCompany.id,
      'companyTaskCategories',
      editingCategory.id,
    )
    const { color, name } = editingCategory

    await updateDoc(catRef, {
      name,
      ...(color && { color }),
    })

    setCompanyTaskCategories(
      companyTaskCategories.map(cat =>
        cat.id === editingCategory.id ? editingCategory : cat,
      ),
    )

    setEditingCategory({} as Category)
  }

  const addCategory = async (label: string) => {
    if (!label) {
      setErrorMessage({
        title: 'Please enter a value',
        message: 'You can’t save an empty category name.',
      })
      return
    }

    const category = {
      name: label,
      color: newCategory.color || TagColors[0].primaryColor,
    }

    const newlyAddedCategory = await addNewCategory(
      `${selectedCompany.id}`,
      'companyTaskCategories',
      category.name,
      category.color,
    )

    setCompanyTaskCategories([
      ...companyTaskCategories,
      {
        id: newlyAddedCategory.id,
        name: category.name,
        color: category.color,
      },
    ])

    setNewCategory({ label: '', color: '' })
    setAddCategoryOpen(false)
  }

  const deleteCategory = async (category: Category) => {
    await deleteDoc(
      doc(
        firestore,
        'companies',
        selectedCompany.id,
        'companyTaskCategories',
        category.id,
      ),
    )
    setCompanyTaskCategories(
      companyTaskCategories.filter(cat => cat.id !== category.id),
    )
  }

  if (!companyTaskCategories) {
    return <LoadingIndicator />
  }

  return (
    <div style={{ padding: '10px 25px' }}>
      <FlexRow
        justify="space-between"
        align="center"
        style={{ marginBottom: 24 }}
      >
        <div>
          <h2>Categories</h2>
          <p style={{ color: theme.colors.text250 }}>
            Manage your task categories here. You can edit names, colors, or
            remove categories that are no longer needed.
          </p>
        </div>
        <TextButton onClick={() => setAddCategoryOpen(true)}>
          <span className="material-symbols-rounded" style={{ fontSize: 20 }}>
            add
          </span>
          <p style={{ fontSize: 14 }}>Add</p>
        </TextButton>
      </FlexRow>

      <Divider style={{ marginBottom: 0, marginTop: 10 }} />
      <Table>
        <TableBody>
          {orderBy(companyTaskCategories, c => c.name.toLowerCase()).map(
            category => (
              <TableRow key={category.id}>
                <TableCell style={{ width: 35 }}>
                  <FlexRow
                    align="center"
                    justify="center"
                    style={{
                      height: 24,
                      width: 24,
                      padding: '7px',
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: category.color || '#F1F2F4',
                        borderColor: '#C9CED6',
                        borderWidth: 0.5,
                        height: 10,
                        width: 10,
                        borderRadius: '50%',
                      }}
                    />
                  </FlexRow>
                </TableCell>
                <TableCell>
                  <FlexRow>
                    <TruncatedText style={{ color: '#0A1F44' }}>
                      <strong style={{ fontWeight: 500 }}>
                        {category.name}
                      </strong>
                    </TruncatedText>
                  </FlexRow>
                </TableCell>
                <TableCell style={{ width: 40 }}>
                  <MultilevelDropdown
                    position={Positions.Left}
                    trigger={<IconButton />}
                    title={
                      <span
                        className="material-symbols-rounded"
                        style={{ fontSize: 20 }}
                      >
                        more_horiz
                      </span>
                    }
                  >
                    <MultilevelItem
                      onClick={() => setEditingCategory(category)}
                    >
                      Edit category
                    </MultilevelItem>
                    <MultilevelItem onClick={() => deleteCategory(category)}>
                      <span style={{ color: theme.colors.destructive }}>
                        Delete category
                      </span>
                    </MultilevelItem>
                  </MultilevelDropdown>
                </TableCell>
              </TableRow>
            ),
          )}
        </TableBody>
      </Table>

      {/* Edit Category Modal */}
      <Modal
        isOpen={!!editingCategory?.id}
        onRequestClose={() => {
          setEditingCategory({} as Category)
          setErrorMessage({ title: '', message: '' })
        }}
        style={{ content: { width: '448px' } }}
      >
        <FlexColumn style={{ gap: 16 }}>
          <h2>Edit Category</h2>
          <FlexRow style={{ gap: 8, alignItems: 'stretch', width: '100%' }}>
            <div
              onClick={event => setAnchorEl(event.currentTarget)}
              style={{
                width: '40px',
                height: '40px',
                border: `1px solid ${theme.colors.border}`,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'white',
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: editingCategory?.color || '#C9CED6',
                  borderRadius: '50%',
                  border: '0.5px solid #C9CED6',
                }}
              />
            </div>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={() => setAnchorEl(null)}
            >
              <ColorSelector
                selectedColor={editingCategory?.color || ''}
                onClick={color => {
                  setEditingCategory({
                    ...editingCategory,
                    color,
                  })
                  setAnchorEl(null)
                }}
              />
            </Menu>
            <TextInput
              value={editingCategory?.name || ''}
              onChange={event =>
                setEditingCategory({
                  ...editingCategory,
                  name: event.target.value,
                })
              }
              placeholder="Category name"
              style={{
                flex: 1,
                height: '40px',
              }}
            />
          </FlexRow>
          {errorMessage.title && !editingCategory?.name && (
            <span style={{ color: theme.colors.destructive, fontSize: 12 }}>
              {errorMessage.message}
            </span>
          )}
          <FlexRow
            style={{
              gap: 8,
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextButton
              onClick={() => {
                setEditingCategory({} as Category)
                setErrorMessage({ title: '', message: '' })
              }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              onClick={() => {
                if (!editingCategory?.name) {
                  setErrorMessage({
                    title: 'Please enter a value',
                    message: 'You can’t save an empty category name.',
                  })
                  return
                }
                updateCategory()
              }}
              disabled={!editingCategory?.name}
            >
              Save
            </PrimaryButton>
          </FlexRow>
        </FlexColumn>
      </Modal>

      {/* Add Category Modal */}
      <Modal
        isOpen={addCategoryOpen}
        onRequestClose={() => {
          setAddCategoryOpen(false)
          setNewCategory({ label: '', color: '' })
          setErrorMessage({ title: '', message: '' })
        }}
        style={{ content: { width: '448px' } }}
      >
        <FlexColumn style={{ gap: 16 }}>
          <h2>Add Category</h2>
          <FlexRow style={{ gap: 8, alignItems: 'stretch', width: '100%' }}>
            <div
              onClick={event => setAddAnchorEl(event.currentTarget)}
              style={{
                width: '40px',
                height: '40px',
                border: `1px solid ${theme.colors.border}`,
                borderRadius: '4px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                backgroundColor: 'white',
                flexShrink: 0,
              }}
            >
              <div
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: newCategory.color || '#C9CED6',
                  borderRadius: '50%',
                  border: '0.5px solid #C9CED6',
                }}
              />
            </div>
            <Menu
              anchorEl={addAnchorEl}
              open={Boolean(addAnchorEl)}
              onClose={() => setAddAnchorEl(null)}
            >
              <ColorSelector
                selectedColor={newCategory.color || ''}
                onClick={color => {
                  setNewCategory({
                    ...newCategory,
                    color,
                  })
                  setAddAnchorEl(null)
                }}
              />
            </Menu>
            <TextInput
              value={newCategory.label}
              onChange={event =>
                setNewCategory({
                  ...newCategory,
                  label: event.target.value,
                })
              }
              placeholder="Add category"
              style={{
                flex: 1,
                height: '40px',
                width: '100%',
              }}
            />
          </FlexRow>
          {errorMessage.title && !newCategory.label && (
            <span style={{ color: theme.colors.destructive, fontSize: 12 }}>
              {errorMessage.message}
            </span>
          )}
          <FlexRow
            style={{
              gap: 8,
              justifyContent: 'flex-end',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <TextButton
              onClick={() => {
                setAddCategoryOpen(false)
                setNewCategory({ label: '', color: '' })
                setErrorMessage({ title: '', message: '' })
              }}
            >
              Cancel
            </TextButton>
            <PrimaryButton
              onClick={() => {
                if (!newCategory.label) {
                  setErrorMessage({
                    title: 'Please enter a value',
                    message: 'You can’t save an empty category name.',
                  })
                  return
                }
                addCategory(newCategory.label)
              }}
              disabled={!newCategory.label}
            >
              Add category
            </PrimaryButton>
          </FlexRow>
        </FlexColumn>
      </Modal>
    </div>
  )
}

export default CategoriesSettings
